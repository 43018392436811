'use strict';
// var _APP;

const $ = jQuery = window.$ = window.jQuery = window.jquery = require('jquery');

// import jQuery from 'jquery';
//
// window.$ = window.jQuery = jQuery;

require('bootstrap');
require('popper.js');

// import 'popper.js';
import Dropdown from './components/Dropdown.js';
import Sticky from './components/Sticky.js';
// import ArticlesHeadline from './components/ArticlesHeadline.js';
// import ArticlesSlider from './components/ArticlesSlider.js';
import OffCanvas from './components/OffCanvas.js';
import BackToTop from './components/BackToTop.js';
import SwiperCarousel from './components/SwiperCarousel.js';
import MailchimpForm from './components/MailchimpForm.js';
// import UploadMain from './components/UploadMain.js';

jQuery(() => {
    new Dropdown;
    new Sticky;
    // new ArticlesHeadline;
    // new ArticlesSlider;
    new OffCanvas;
    new BackToTop;
    new SwiperCarousel;
    new MailchimpForm;
    // new UploadMain;

    $('.hasTooltip').tooltip();

    $(".modal").on("hidden.bs.modal", function () {
        $(".modal-backdrop").remove();
    });
});
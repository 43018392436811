class Dropdown {
    constructor() {
        $('.main-menu-container ul.navbar-nav li.dropdown').hover(e => {
            $(e.currentTarget).find('.dropdown-menu').stop(true, true).delay(100).fadeIn(500);//.addClass('d-flex');
        }, e => {
            $(e.currentTarget).find('.dropdown-menu').stop(true, true).delay(100).fadeOut(500);//.removeClass('d-flex');
        });
    }
}

export default Dropdown;
class OffCanvas {
    constructor() {
        let offCanvasOpen = document.querySelectorAll('.offcanvas-toggler');
        let offCanvasClose = document.querySelector('.off-canvas__close');
        let offCanvas = document.querySelector('.off-canvas');
        offCanvasOpen.forEach((el) => {
            el.onclick = () => {
                offCanvas.classList.toggle('show');
            };
        });

        offCanvasClose.onclick = () => {
            offCanvas.classList.toggle('show');
        };

        let dropdownItems = document.querySelectorAll('.off-canvas .nav-item.dropdown');

        dropdownItems.forEach((el) => {
            let dropdown = el.querySelector('.dropdown-menu');
            let expandButton = document.createElement("button");
            expandButton.classList.add('btn', 'btn-link', 'btn-sm');
            let expandIcon = document.createElement("i");
            expandIcon.classList.add('fas', 'fa-plus');
            expandButton.appendChild(expandIcon);
            el.insertBefore(expandButton, dropdown);

            expandButton.onclick = (e) => {
                e.preventDefault();
                dropdown.classList.toggle('d-block');
            }
        });

    }
}

export default OffCanvas;
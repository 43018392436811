class BackToTop {
    constructor() {
        var backToTopButton = $('.back-to-top-button');

        backToTopButton.click((e) => {
            e.preventDefault();
            $('html, body').animate({scrollTop: 0}, '300');
        });

        $(window).scroll(() => {
            var scroll_top = $(window).scrollTop();
            if (scroll_top > 500) {
                backToTopButton.fadeIn(250);
            } else {
                backToTopButton.fadeOut(250);
            }
        });
    }
}

export default BackToTop;
class MailchimpForm {
    constructor() {
        let mailchimpFormConatiner = document.querySelector('.cmc-signup');
        if (mailchimpFormConatiner) {
            this.cmc(mailchimpFormConatiner);
        }
    }

    cmc(div) {
        var $div = jQuery(div);
        var form = $div.find('form');
        form.submit(function (e) {
            e.preventDefault();
            if (form.find('button').hasClass('disabled')) {
                return;
            }
            jQuery.ajax({
                type: "POST",
                dataType: "json",
                url: form.attr('action'),
                data: form.serialize() + '&ajax=true',
                beforeSend: function () {
                    form.addClass('cmc-loading');
                    form.find('.cmc-spinner').first().css('display', 'inline-block');
                }
            }).done(function (data) {
                //var div = form.parent('div');
                form.find('.cmc-spinner').first().css('display', 'none');
                form.removeClass('cmc-loading');
                if (data.error == true) {
                    $div.find('.cmc-error').css('display', 'block').append(data.html);
                } else {
                    if (data.html == 'updated') {
                        $div.find('.cmc-updated').first().css('display', 'block');
                    } else {
                        $div.find('.cmc-saved').first().css('display', 'block');
                    }

                    form.hide(400, function () {
                        jQuery('html, body').animate({
                            scrollTop: $div.offset().top
                        }, 200);
                    });
                }
            });

            return false;
        });

        var t;
        form.find('input[name*="EMAIL"]').keyup(function () {
            clearTimeout(t);
            t = setTimeout(sub_exist, 400);
        });

        function sub_exist() {
            jQuery.ajax({
                type: 'POST',
                dataType: "json",
                url: form.attr('action').replace('subscription.save', 'subscription.exist'),
                data: form.serialize() + '&ajax=true'
            }).done(function (data) {
                var message = jQuery('.cmc-exist'), button = form.find('button');
                console.log(data);
                message.addClass('d-none');
                button.attr('disabled', false).removeClass('disabled');
                if (data.exists) {
                    message.removeClass('d-none');
                    message.find('a').attr('href', data.url);
                    button.attr('disabled', true).addClass('disabled');
                }

            });
        }

        $div.find('.cmc-toggle-sub').on('click', function () {
            $div.find('.cmc-existing').toggleClass('d-none');
        });
    };
}

export default MailchimpForm;
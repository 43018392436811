import StickyJS from 'sticky-js';

import stickybits from 'stickybits'

class Sticky {
    constructor() {
        var headerHeight = document.querySelector('header.header').offsetHeight;
        var mainMenu = document.querySelector('.main-menu-container');
        var menuHeight = mainMenu.offsetHeight;

        const stickybitsItem = stickybits('.stickybit, .stickybits .sppb-addon-wrapper', {
            useStickyClasses: true,
            stickyBitStickyOffset: menuHeight + 10
        });
        stickybitsItem.update();
        // when the url hash changes
        window.addEventListener('hashchange', () => {
            stickybitsItem.update();
        });

        let stickyjsItem = new StickyJS('.sticky', {
            className: 'js-is-sticky',
        });
        window.onresize = () => {
            stickyjsItem.update();
        };

        let sideskins = document.querySelectorAll('.sideskin');
        window.onscroll = () => {

            if (window.pageYOffset >= headerHeight - menuHeight) {
                sideskins.forEach((sideskin) => {
                    sideskin.style.top = menuHeight - 1 + 'px';
                    sideskin.style.position = 'fixed';
                });
            } else {
                sideskins.forEach((sideskin) => {
                    sideskin.style.position = 'absolute';
                    sideskin.style.top = '0';
                });
            }
        }
    }
}

export default Sticky;